.abs_div {
    position: absolute;
    top: 30px;
    left: 30px;   
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 5;
}
 
.home_screen_wrapper {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    
    position: relative;
    overflow: hidden !important;
    
}
.logo_container {
    width: 100vw;
    height: 100px;
    padding-left: 30px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
    font-size: var(--txt-bold);
    color: var(--yellow-txt);
    font-weight: 700;
}

.home_screen_options_container {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: flex-start;
    margin-top: 20px;
}

.italic_txt {
    font-style: italic;
}
.link {
    font-size: var(--txt-key_board);
    color: var(--wh-text);
    margin-bottom: 20px; 
    cursor: pointer;
}
/* .link:hover {   
    text-decoration: underline;
    text-underline-offset: 7px;    
    transform: scale(1.05) translateY(-3px);
} */
.no_sound {
    color:rgb(198, 198, 198);
    cursor:not-allowed;
    font-size: large;
   }